import { InteractionType } from '@azure/msal-browser';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsalAuthentication } from '@azure/msal-react';
import { Box } from '@mui/material';
import React from 'react';
import { useUnSafeUser } from '../hooks';
import { AuthInProgress } from '../providers';

export function AuthenticationRequired({ children }: React.PropsWithChildren<unknown>) {
    useMsalAuthentication(InteractionType.Redirect);
    return (
        <>
            <AuthenticatedTemplate>
                <AppUserGuard>{children}</AppUserGuard>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Box sx={{ pt: 4 }}>
                    <AuthInProgress />
                </Box>
            </UnauthenticatedTemplate>
        </>
    );
}

export function AppUserGuard({ children }: React.PropsWithChildren<unknown>) {
    const user = useUnSafeUser();
    if (!user) {
        return null;
    }
    return <>{children}</>;
}

import { Dashboard, Add } from '@mui/icons-material';
import { I2PEnvironment } from '@price-for-profit/micro-services';

const routeConfig: EnvironmentNavigation[] = [
    {
        permission: 'account',
        application: {
            applicationName: 'Drive Account',
            env: {
                local: {
                    applicationId: 'e8245bd0-ad5a-437f-9183-b195ca7dcb54',
                    deployedUrl: 'https://localhost:5001/apps/account',
                },
                dev: {
                    applicationId: 'e8245bd0-ad5a-437f-9183-b195ca7dcb54',
                    deployedUrl: 'https://nouryon.dev.insight2profit.com/apps/account',
                },
                sandbox: {
                    applicationId: '594a3feb-e94d-41c2-b53c-bc6280905e63',
                    deployedUrl: 'https://nouryon.sandbox.insight2profit.com/apps/account',
                },
                prod: {
                    applicationId: 'fff17357-24e8-4d49-bf72-a9dc9df81954',
                    deployedUrl: 'https://nouryon.insight2profit.com/apps/account',
                },
            },
        },
        links: [
            {
                title: 'Analytics Dashboard',
                url: '/analytics',
                icon: <Dashboard sx={{ color: 'primary.main' }} />,
            },
        ],
    },
    {
        permission: 'pmt',
        application: {
            applicationName: 'Price Management',
            env: {
                local: {
                    applicationId: 'd8e52422-e1be-48e8-ab19-26147a304ac8',
                    deployedUrl: 'https://localhost:5003/apps/pmt',
                },
                dev: {
                    applicationId: 'd8e52422-e1be-48e8-ab19-26147a304ac8',
                    deployedUrl: 'https://nouryon.dev.insight2profit.com/apps/pmt',
                },
                sandbox: {
                    applicationId: 'c3fb09e3-c00c-468c-b25e-005594365133',
                    deployedUrl: 'https://nouryon.sandbox.insight2profit.com/apps/pmt',
                },
                prod: {
                    applicationId: 'c8ba0f5d-9b8b-4055-9089-8e6b6c5606a8',
                    deployedUrl: 'https://nouryon.insight2profit.com/apps/pmt',
                },
            },
        },
        links: [
            {
                title: 'Product Prices',
                url: '/product-prices',
                icon: <Dashboard sx={{ color: 'primary.main' }} />,
            },
            {
                title: 'Customer Prices',
                url: '/customer-prices',
                icon: <Dashboard sx={{ color: 'primary.main' }} />,
            },
        ],
    },
    {
        permission: 'pd',
        application: {
            applicationName: 'Parameter Dashboard',
            env: {
                local: {
                    applicationId: 'd1d95732-510f-41ff-ab43-b2356cc2002a',
                    deployedUrl: 'https://localhost:5005/apps/pd',
                },
                dev: {
                    applicationId: 'd1d95732-510f-41ff-ab43-b2356cc2002a',
                    deployedUrl: 'https://nouryon.dev.insight2profit.com/apps/pd',
                },
                sandbox: {
                    applicationId: 'dfedd651-cbfb-43cf-8ede-7446bdb0bc7a',
                    deployedUrl: 'https://nouryon.sandbox.insight2profit.com/apps/pd',
                },
                prod: {
                    applicationId: '379e8891-4d99-4503-84dc-dc7790c3d4b6',
                    deployedUrl: 'https://nouryon.insight2profit.com/apps/pd',
                },
            },
        },
        links: [
            {
                title: 'Scenarios',
                url: '/scenarios',
                icon: <Dashboard sx={{ color: 'primary.main' }} />,
            },
            {
                title: 'New Scenario',
                url: '/scenarios/new',
                icon: <Add sx={{ color: 'primary.main' }} />,
            },
        ],
    },
];

interface NavigationLink {
    title: string;
    url: string;
    icon?: JSX.Element;
}

interface EnvironmentNavigation {
    application: {
        applicationName: string;
        env: Record<Exclude<I2PEnvironment, 'model'> | 'local', { applicationId: string; deployedUrl: string }>;
    };
    links: NavigationLink[];
    permission: string;
}

interface DriveAccountNavigation {
    application: {
        applicationId: string;
        applicationName: string;
        deployedUrl: string;
    };
    links: NavigationLink[];
    local?: boolean;
}

interface BuildDriveAccountNavigationProps {
    applicationId: string;
    environment: Exclude<I2PEnvironment, 'model'>;
    useLocal?: boolean;
    permissionMap: Record<string, boolean>;
}

export function buildDriveAccountNavigation({
    applicationId,
    environment,
    useLocal,
    permissionMap,
}: BuildDriveAccountNavigationProps): DriveAccountNavigation[] {
    const localEnvironment = environment === 'dev' && useLocal ? 'local' : environment;

    return routeConfig.reduce<DriveAccountNavigation[]>((nav, { links, application, permission }) => {
        const envConfig = application.env[localEnvironment];
        if (permissionMap[permission]) {
            nav.push({
                application: {
                    applicationName: application.applicationName,
                    ...envConfig,
                },
                links,
                local: applicationId === envConfig.applicationId,
            });
        }
        return nav;
    }, []);
}

export const showHamburger = routeConfig.length > 1;

import { hookstate, State, useHookstate } from '@hookstate/core';

interface AuthState {
    user: app.UserInfo | null;
}

const initialState: AuthState = {
    user: null,
};

const authState = hookstate(initialState);

const wrapState = (s: State<AuthState>) => ({
    getUser: () => s.user.value,
});

export function useAuthState() {
    return useHookstate(authState);
}

export function accessAuthState() {
    return wrapState(authState);
}

import { AccountInfo } from '@azure/msal-browser';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ClientId, LoginPermissionKey } from '../constants';
import { DEFAULT_ROUTES } from '../routing';
import { useAuthState } from '../stores';
import { DefaultUserInfo, InsightB2CClaims } from '../types';
import { getAppPermissions } from '../utility';

const getDefaultUserInfoFromIdClaims = ({
    given_name,
    family_name,
    name,
    email,
    sub,
    InsightClaims,
}: Record<string, string>): DefaultUserInfo => ({
    firstName: given_name,
    lastName: family_name,
    email,
    displayName: name,
    id: sub,
    insightClaims: InsightClaims,
});

interface UseHandleAccountProps {
    getUserInfo?: (user: DefaultUserInfo) => app.UserInfo;
}
export function useHandleAccount({ getUserInfo }: UseHandleAccountProps) {
    const authState = useAuthState();
    const navigate = useNavigate();

    const handleUserAccount = React.useCallback(
        (account: AccountInfo): void => {
            const loginPermission: `${ClientId}.${LoginPermissionKey}` = 'nouryon.login';
            if (account.idTokenClaims) {
                const defaultUser = getDefaultUserInfoFromIdClaims(account.idTokenClaims as InsightB2CClaims);
                const appPermissions = getAppPermissions(defaultUser.insightClaims);
                if (!appPermissions.includes(loginPermission)) {
                    navigate(DEFAULT_ROUTES.unauthorized);
                }
                const resolvedUser = getUserInfo ? getUserInfo(defaultUser) : defaultUser;

                authState.user.set(prevUser => {
                    if (!prevUser) {
                        return resolvedUser;
                    }

                    if (prevUser.insightClaims !== defaultUser.insightClaims) {
                        return resolvedUser;
                    }
                    return prevUser;
                });
            }
        },
        [navigate, authState, getUserInfo]
    );
    const setUserNull = React.useCallback(() => {
        authState.user.set(null);
    }, [authState]);

    return {
        handleUserAccount,
        setUserNull,
    } as const;
}

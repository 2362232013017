import { EventData } from '@price-for-profit/micro-services';
import { UseMutationResult, UseQueryResult } from '@tanstack/react-query';
import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { useAnalyticsReportsQuery, useTrackViewPageMutation } from '../queries';
import { AnalyticsReport, TrackViewPageParams } from '../types';

type AnalyticsAvailableReportsContext = {
    reportQuery: UseQueryResult<AnalyticsReport[], Error>;
    trackViewPageMutation?: UseMutationResult<EventData, Error, TrackViewPageParams, unknown>;
};

const analyticsAvailableReportsContext = createContext<AnalyticsAvailableReportsContext>(
    {} as AnalyticsAvailableReportsContext
);

export function ProvideAvailableAnalyticsReports({ children }: PropsWithChildren<{}>) {
    const reportQuery = useAnalyticsReportsQuery();
    const trackViewPageMutation = useTrackViewPageMutation();

    const context = useMemo(
        () => ({
            reportQuery,
            trackViewPageMutation,
        }),
        [reportQuery, trackViewPageMutation]
    );

    return (
        <analyticsAvailableReportsContext.Provider value={context}>
            {children}
        </analyticsAvailableReportsContext.Provider>
    );
}

export function useAvailableAnalyticsReports() {
    return useContext(analyticsAvailableReportsContext);
}

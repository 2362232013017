import md5 from 'blueimp-md5';
import { format } from 'date-fns/fp';

const moneyFormatter = new Intl.NumberFormat('en', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    minimumIntegerDigits: 1,
    currencyDisplay: 'symbol',
});

const MoneyNoDecimalFormatter = new Intl.NumberFormat('en', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
    minimumIntegerDigits: 1,
    currencyDisplay: 'symbol',
});

const commaSeparatedNumberFormatter = new Intl.NumberFormat('en-US');

const percentFormatter = new Intl.NumberFormat('en', {
    style: 'percent',
    minimumFractionDigits: 1,
    minimumIntegerDigits: 1,
});

export const formatMoney = (value: number) => moneyFormatter.format(value);
export const formatMoneyNoDecimal = (value: number) => MoneyNoDecimalFormatter.format(value);
export const formatPercent = (value: number) => percentFormatter.format(value);
export const formatCommaSeparatedNumber = (value: number) => commaSeparatedNumberFormatter.format(value);

export const formatDate: (value: number | Date) => string = format('MM-dd-yy');

export const formatDateWithTime: (value: number | Date) => string = format('MMddyyhhmm');

export const determineColor = (value?: number) => {
    if (value === null || value === undefined) return 'black';
    if (value > 0) return 'green';
    if (value < 0) return 'red';
    return 'black';
};

export const formatLongDateString = (dateParam: string | undefined): string => {
    if (!dateParam) {
        return '';
    }
    const date = new Date(dateParam);
    const dateString = date.toLocaleDateString();
    const timeString = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', timeZoneName: 'short' });
    return `${dateString} ${timeString}`;
};

export const createHash = (phrase: string) => md5(phrase);

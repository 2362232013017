import { InteractionStatus } from '@azure/msal-browser';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { Box, Button, IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { useTitle } from '../../hooks';
import { AuthInProgress, useService } from '../../providers';

function Div({ children }: PropsWithChildren<unknown>) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                p: 2,
                gap: 4,
                height: '100%',
                width: '100%',
            }}
        >
            {children}
        </Box>
    );
}

interface LoginPageProps {
    tabTitle: string;
    clientLogo: JSX.Element;
    insightLoginLogo: JSX.Element;
    loginRedirectRoute: string;
}

export function LoginPage({ tabTitle, clientLogo, insightLoginLogo, loginRedirectRoute }: LoginPageProps) {
    useTitle(tabTitle);
    const { enqueueSnackbar } = useSnackbar();
    const msal = useMsal();
    const { nextAuthority, authClientName } = useService();
    const { instance, inProgress } = msal;
    const login = async () => {
        try {
            instance.loginRedirect();
        } catch (error: any) {
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    };
    if (
        inProgress !== InteractionStatus.None &&
        inProgress !== InteractionStatus.Startup &&
        (inProgress === InteractionStatus.Login || inProgress === InteractionStatus.HandleRedirect)
    ) {
        return (
            <Div>
                <AuthInProgress />
            </Div>
        );
    }

    return (
        <>
            <AuthenticatedTemplate>
                <Navigate to={loginRedirectRoute} replace />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Div>
                    <Box>{clientLogo}</Box>
                    <Box>{insightLoginLogo}</Box>
                    <Box sx={{ width: '75%', textAlign: 'center' }}>
                        <Button fullWidth size='small' variant='contained' color='secondary' onClick={login}>
                            {authClientName} Login
                        </Button>
                    </Box>
                    <IconButton onClick={() => nextAuthority()}>
                        <SwapHorizIcon />
                    </IconButton>
                </Div>
            </UnauthenticatedTemplate>
        </>
    );
}

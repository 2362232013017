import { useMemo } from 'react';
import { getAppPermissions } from '../utility';
import { useUser } from './use-user';

export function useNavigatePermission(): Record<string, boolean> {
    const user = useUser();
    const appPermissions = useMemo(() => getAppPermissions(user.insightClaims), [user]);

    return useMemo(
        () =>
            appPermissions.reduce<Record<string, boolean>>((map, permission) => {
                if (permission.includes('navigate')) {
                    const key = permission.split('.').at(-1) || '';
                    map[key] = true;
                }
                return map;
            }, {}),
        [appPermissions]
    );
}

import { useUnSafeUser } from './use-un-safe-user';

export function useUser() {
    const user = useUnSafeUser();

    if (!user) {
        throw new Error('No user found. Make sure useUser is being used as a child of <AppUserGuard />');
    }

    return user;
}

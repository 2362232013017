import { ObjectTyped } from '@price-for-profit/micro-services-common';
import { CLIENT_ID } from '../constants';

export interface AuthorityUrlObj {
    authorityUrl: string;
    name: string;
}

export const LAST_B2C_AUTHORITY_NAME_KEY = `last-b2c-authority-name-${CLIENT_ID}`;

export function getAuthorityUrlInfo(authorityUrlObjs: AuthorityUrlObj[]) {
    const selectedAuthorityUrl = determineAuthorityUrl(authorityUrlObjs);

    const index = authorityUrlObjs.indexOf(selectedAuthorityUrl);
    localStorage.setItem(LAST_B2C_AUTHORITY_NAME_KEY, index.toString());

    return { ...selectedAuthorityUrl, acr: getAcrFromAuthorityUrl(selectedAuthorityUrl.authorityUrl) };
}

export function nextAuthority() {
    const lastB2cAuthorityIndex = Number(localStorage.getItem(LAST_B2C_AUTHORITY_NAME_KEY)) || 0;

    localStorage.setItem(LAST_B2C_AUTHORITY_NAME_KEY, (lastB2cAuthorityIndex + 1).toString());
    window.location.reload();
}

function determineAuthorityUrl(authorityUrlObjs: AuthorityUrlObj[]) {
    const authRedirectQueryParams: AuthRedirectQueryParams | undefined = parseRedirectQueryParams();

    if (authRedirectQueryParams?.client_info?.acr) {
        const authorityUrlUsedForRedirect = authorityUrlObjs.find(authorityUrlObj =>
            authorityUrlObj.authorityUrl.includes(authRedirectQueryParams?.client_info?.acr)
        );
        if (authorityUrlUsedForRedirect) return authorityUrlUsedForRedirect;
    }

    let lastB2cAuthorityIndex = Number(localStorage.getItem(LAST_B2C_AUTHORITY_NAME_KEY));

    if (!isNaN(lastB2cAuthorityIndex)) {
        if (lastB2cAuthorityIndex >= authorityUrlObjs.length) {
            localStorage.setItem(LAST_B2C_AUTHORITY_NAME_KEY, '0');
            lastB2cAuthorityIndex = 0;
        }
        const authorityUrlLastSelectedByUser = authorityUrlObjs[lastB2cAuthorityIndex];
        if (authorityUrlLastSelectedByUser) return authorityUrlLastSelectedByUser;
    }

    // const authorityUrlLastLoggedInByUser = authorityUrlObjs.find(authorityUrlObj =>
    //     isMatchingLoggedInAuthAuthority(authorityUrlObj)
    // );
    // if (authorityUrlLastLoggedInByUser) return authorityUrlLastLoggedInByUser;

    const firstAuthorityUrlObj = authorityUrlObjs[0];
    return firstAuthorityUrlObj;
}

const getAcrFromAuthorityUrl = (authorityUrl: string) => authorityUrl.split('/').at(-1);

interface AuthRedirectQueryParams {
    state: string;
    client_info: { uid: string; utid: string; acr: string };
    code: string;
}

function parseRedirectQueryParams(): AuthRedirectQueryParams | undefined {
    return window.location.href
        .split('#')?.[1]
        ?.split('&')
        ?.reduce(
            (acc, curr) => {
                const [key, value] = curr.split('=');
                const decodedValue = decodeURIComponent(value);
                const atobValue = ['state', 'client_info'].includes(key)
                    ? JSON.parse(window.atob(decodedValue))
                    : decodedValue;
                const acr = key === 'client_info' ? atobValue?.uid?.split('-')?.at(-1) : undefined;
                const atobValueWithAcr = { ...atobValue, acr };
                return { ...acc, [key]: atobValueWithAcr };
            },
            { state: '', client_info: { uid: '', utid: '', acr: '' }, code: '' }
        );
}

function isMatchingLoggedInAuthAuthority(authorityUrlObj: AuthorityUrlObj) {
    const authAuthorityName = authorityUrlObj.authorityUrl.split('/').at(-1);
    const sessionStorageKeys = ObjectTyped.keys(sessionStorage);
    const localStorageKeys = ObjectTyped.keys(localStorage);

    const isMatchingLoggedIn =
        sessionStorageKeys.some(sessionStorageKey =>
            sessionStorageKey.toLowerCase().includes(authAuthorityName?.toLowerCase() || 'no authority url')
        ) ||
        localStorageKeys.some(localStorageKey =>
            localStorageKey.toLowerCase().includes(authAuthorityName?.toLowerCase() || 'no authority url')
        );

    return isMatchingLoggedIn;
}

import TourOutlinedIcon from '@mui/icons-material/TourOutlined';
import { Box, Stack, Typography } from '@mui/material';
import { Header, SupportMenu, UserMenu } from '../../components';
import { SUPPORT_PORTAL_URL } from '../../constants';
import { useService } from '../../providers';
interface UnauthorizedPageProps {
    logo: JSX.Element;
    driveLogo: JSX.Element;
    trainingGuideLink?: string;
}
export function UnauthorizedPage({ logo, trainingGuideLink, driveLogo }: UnauthorizedPageProps) {
    const {
        appConfig: { serviceBaseUrl },
    } = useService();

    return (
        <>
            <Header serviceBaseUrl={serviceBaseUrl}>
                <Box justifySelf='start'></Box>
                <Box justifySelf='center'>{logo}</Box>
                <Box justifySelf='end'>
                    <Stack direction='row' spacing={1}>
                        <SupportMenu supportPortalLink={SUPPORT_PORTAL_URL} trainingGuideLink={trainingGuideLink} />
                        <UserMenu />
                    </Stack>
                </Box>
            </Header>
            <Box sx={{ display: 'flex', height: 'calc(100% - 100px)', flexDirection: 'column' }}>
                <Stack alignSelf='center' alignItems='center' justifyContent='center' gap={4} sx={{ flex: 1 }}>
                    <TourOutlinedIcon sx={{ fontSize: 128, color: 'info.light' }} />
                    <Stack direction='row' alignItems='center' gap={1}>
                        <Typography variant='h6' fontWeight='bold'>
                            We're Working To Get You Access
                        </Typography>
                    </Stack>
                    <Typography variant='body1' color='text.secondary' textAlign='center'>
                        Thank you for registering your account for this <br />
                        application. Your user will be set up with access in the <br />
                        next 72 hours.
                    </Typography>
                </Stack>
                <Stack direction='row' justifyContent='center'>
                    {driveLogo}
                </Stack>
            </Box>
        </>
    );
}

import { IAuthorizationService, IEventService, IPowerBiService } from '@price-for-profit/micro-services';
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack';
import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { AnalyticsReportService, IAnalyticsReportService } from '../services';

type ProvidePrerequisitesProps = {
    prerequisites: {
        services: {
            authService: IAuthorizationService;
            pbiService: IPowerBiService;
            eventService: IEventService;
        };
        appInfo: {
            applicationId: string;
            clientId: string;
            userId: string;
            appName: string;
        };
        navigate: NavigateFunction;
    };
    options?: {
        alwaysSameReport?: {
            name: string;
        };
        analyticsPagePath?: string;
        snackbar?: {
            enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey;
        };
    };
};

type PrerequisitesContext = {
    analyticsReportService: IAnalyticsReportService;
    options?: ProvidePrerequisitesProps['options'];
    navigate: NavigateFunction;
};

const analyticsPrerequisitesContext = createContext<PrerequisitesContext>({} as PrerequisitesContext);

export function ProvideAnalyticsPrerequisites({
    children,
    prerequisites: { services, appInfo, navigate },
    options,
}: PropsWithChildren<ProvidePrerequisitesProps>) {
    const value = useMemo(() => {
        const analyticsReportService = new AnalyticsReportService(
            services.authService,
            services.eventService,
            services.pbiService,
            appInfo.clientId,
            appInfo.applicationId,
            appInfo.userId
        );

        return {
            analyticsReportService,
            options,
            navigate,
        };
    }, [options, navigate, appInfo, services]);

    return <analyticsPrerequisitesContext.Provider value={value}>{children}</analyticsPrerequisitesContext.Provider>;
}

export function useAnalyticsPrerequisites() {
    return useContext(analyticsPrerequisitesContext);
}
